<div class="relative">
	<!-- gray right panel -->
	<div
		class="absolute top-0 right-0 hidden w-1/3 h-full ml-auto lg:block bg-gray-50"
	></div>

	<div class="relative z-50 grid grid-cols-1 lg:grid-cols-2 lg:container">
		<!-- left panel -->
		<div class="lg:pt-8 lg:pb-11">
			<p class="subnav-heading">Search Open Days</p>
			<div class="lg:grid lg:grid-cols-2 lg:gap-9">
				@for (section of data.sublinks; track $index) {
					<div class="grid grid-cols-1 subnav-link-padding">
						@for (link of section.links; track $index) {
							<uc-nav-link
								[paramKey]="section.param"
								[basePath]="data.browse"
								[linkData]="link"
							/>
						}
					</div>
				}
			</div>
			<div class="subnav-link-padding lg:pt-8">
				<uc-nav-link [basePath]="data.browse" [linkData]="landingCard" />
			</div>
		</div>

		<!-- divider on mobile -->
		<div class="w-full h-px border-t lg:hidden"></div>

		<!-- right panel -->
		@if ($contentCards | async; as cards) {
			<div
				class="py-7 lg:py-0 lg:p-8 lg:pt-8 lg:border-l lg:pb-11 bg-gray-50 side-style"
			>
				<p class="subnav-heading">What's new at Uni Compare</p>
				@for (card of cards; track $index) {
					<uc-nav-card-landscape
						[data]="card"
						(click)="trackPromoSlotIndex($index, card)"
					/>
				}
			</div>
		}
	</div>
</div>
