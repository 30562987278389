export const UNIS_NAV = {
	sublinks: [
		{
			heading: 'Search by Courses Offered',
			param: '',
			links: [
				{
					title: 'Undergraduate Universities',
					value: '/universities/undergraduate',
					description: 'Search unis with undergraduate courses',
				},
				{
					title: 'Postgraduate Universities',
					value: '/universities/postgraduate',
					description: 'Search unis with postgraduate courses',
				},
			],
		},
		{
			heading: 'Search by Region',
			param: 'loc',
			links: [
				{
					title: 'London Universities',
					value: 'ldn',
					description: 'Interested in London? Search here',
				},
				{
					title: 'Scottish Universities',
					value: 'scot',
					description: 'Discover universities in Scotland',
				},
				{
					title: 'Welsh Universities',
					value: 'wales',
					description: 'Want to study in Wales? Search here',
				},
				{
					title: 'Northern Irish Universities',
					value: 'ni',
					description: 'Discover uni’s in Northern Ireland',
				},
			],
		},
	],
	browse: '/universities/undergraduate',
};
