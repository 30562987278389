import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { UNIS_NAV } from '../../../static/subnav-unis.properties';
import { getStateNavUnis } from '../../../data-access/+store/navigation.selectors';
import { NavCard } from '../../../models/navigation.models';
import { UniversityGTMService } from '@uc/web/core';
import { ProductEnum } from '@uc/web/shared/data-models';

@Component({
	selector: 'uc-subnav-unis',
	templateUrl: './subnav-unis.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubNavUnisComponent {
	data = UNIS_NAV;
	unis$ = this._store.select(getStateNavUnis);

	constructor(
		private _store: Store,
		private _uniGTMSrv: UniversityGTMService,
	) {}

	onClick(card: NavCard) {
		if (!card.uniId || !card.uniName || !card.uniOrder) return;
		this._uniGTMSrv.uniProfileClick(
			card.uniId,
			card.uniName,
			card.uniOrder,
			null,
			ProductEnum.Undergraduate,
		);
	}

	onImpression(card: NavCard) {
		if (!card.uniId || !card.uniName || !card.uniOrder) return;
		this._uniGTMSrv.uniImpression(
			card.uniId,
			card.uniName,
			card.uniOrder,
			null,
			ProductEnum.Undergraduate,
		);
	}
}
